import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './AnimatedProductDemo.module.css';
import DailyRoom from './DailyRoom';

const MatrixBackground = () => {
  return (
    <div className={styles.matrixBackground}>
      {[...Array(50)].map((_, i) => (
        <div key={i} className={styles.matrixColumn} style={{ left: `${i * 2}%`, animationDelay: `${Math.random() * 5}s` }}>
          {[...Array(20)].map((_, j) => (
            <span key={j} style={{ animationDelay: `${Math.random() * 5}s` }}>
              {String.fromCharCode(33 + Math.floor(Math.random() * 94))}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
};

const AnimatedText = ({ text, onComplete }) => {
    const [displayText, setDisplayText] = useState('');
    const animationRef = useRef(null);
  
    const animate = useCallback(() => {
      let index = 0;
      if (animationRef.current) clearTimeout(animationRef.current);
      
      const animateNextChar = () => {
        if (index < text.length) {
          setDisplayText(text.slice(0, index + 1));
          index++;
          animationRef.current = setTimeout(animateNextChar, 100);
        } else {
          onComplete();
        }
      };
  
      animateNextChar();
  
      return () => {
        if (animationRef.current) clearTimeout(animationRef.current);
      };
    }, [text, onComplete]);
  
    useEffect(() => {
      const cleanup = animate();
      return cleanup;
    }, [animate]);
  
    return (
      <div className={`${styles.retroText} ${styles.animatedText}`}>
        {displayText}
      </div>
    );
  };
  
  const AnimatedButton = ({ show, onClick, isLoading }) => {
    if (!show) return null;
    return (
      <button 
        className={styles.animatedButton} 
        onClick={onClick}
        disabled={isLoading}
      >
        {isLoading ? 'Loading...' : 'See Demo'}
      </button>
    );
  };
  
  const AnimatedProductDemo = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [showButton, setShowButton] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [dailyUrl, setDailyUrl] = useState(null);
  
    const handleFirstSlideComplete = useCallback(() => {
      setTimeout(() => setCurrentSlide(1), 1000);
    }, []);
  
    const handleSecondSlideComplete = useCallback(() => {
      setShowButton(true);
    }, []);
  
    const handleChatNowClick = useCallback(() => {
      setIsLoading(true);
      setError(null);
      window.open('https://www.loom.com/share/be163ec0c2764751b314fec41f7f00b9?sid=18171a87-197e-44a2-b260-b48a7f36b7c2', '_blank');
      setIsLoading(false);
  
      // const options = {
      //   method: 'POST',
      //   headers: {
      //     'x-api-key': 'f98e082c9ac64467a9508b3817e2f31c',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     replica_id: "ra54d1d861",
      //     persona_id: "p09b2248",
      //     conversation_name: "A Meeting with Lucy"
      //   })
      // };
  
      // fetch('https://tavusapi.com/v2/conversations', options)
      // .then(response => {
      //   if (!response.ok) {
      //     throw new Error('Network response was not ok');
      //   }
      //   return response.json();
      // })
      // .then(data => {
      //   console.log(data);
      //   setApiResponse(data);
      //   if (data && data.conversation_url) {
      //     setDailyUrl(data.conversation_url);
      //   } else {
      //     throw new Error('Conversation room URL not found in the response');
      //   }
      // })
      // .catch(err => {
      //   console.error(err);
      //   setError('An error occurred while fetching data');
      // })
      // .finally(() => {
      //   setIsLoading(false);
      // });
  }, []);
  
  return (
    <div className={styles.container}>
      <MatrixBackground />
      
      <div className={styles.content}>
        {currentSlide === 0 && (
          <AnimatedText 
            key="slide1"
            text="Hi" 
            onComplete={handleFirstSlideComplete}
          />
        )}
        {currentSlide === 1 && (
          <AnimatedText 
            key="slide2"
            // text="Welcome To InvestorHub's Virtual Investor Relationship Manager Demo" 
            text="Welcome to AI Sapiens demo, your next AI co-worker" 
            onComplete={handleSecondSlideComplete}
          />
        )}
        {!dailyUrl && (
          <AnimatedButton 
            show={showButton} 
            onClick={handleChatNowClick}
            isLoading={isLoading}
          />
        )}
        {error && <div className={styles.error}>{error}</div>}
        {dailyUrl && <DailyRoom url={dailyUrl} />}
      </div>
    </div>
  );
  };
  
  export default AnimatedProductDemo;