import React, { useEffect, useRef } from 'react';
import DailyIframe from '@daily-co/daily-js';
import styles from './AnimatedProductDemo.module.css';

const DailyRoom = ({ url }) => {
  const dailyContainerRef = useRef(null);
  const callObjectRef = useRef(null);

  useEffect(() => {
    if (!url || !dailyContainerRef.current) return;

    const createAndJoinCall = async () => {
      if (!callObjectRef.current) {
        callObjectRef.current = DailyIframe.createFrame(dailyContainerRef.current, {
          iframeStyle: {
            width: '100%',
            height: '100%',
            border: '0',
            borderRadius: '12px',
          },
        });
      }

      try {
        await callObjectRef.current.join({ url });
      } catch (error) {
        console.error('Error joining Daily call:', error);
      }
    };

    createAndJoinCall();

    return () => {
      if (callObjectRef.current) {
        callObjectRef.current.destroy();
      }
    };
  }, [url]);

  return (
    <div className={styles.roomContainer}>
      <div ref={dailyContainerRef} className={styles.dailyContainer}></div>
      <iframe
        src="https://ai-spreadsheet-next--new1-7ebce.us-central1.hosted.app/"
        className={styles.websiteView}
        title="Excel AI Website"
        allow="microphone;"
        sandbox="allow-scripts allow-same-origin allow-forms"
      ></iframe>
    </div>
  );
};

export default DailyRoom;